import "core-js/modules/es.array.push.js";
import { checkApplyActive, getSchoolActiveInfo, getTeacherList, courseList, setCourseTeamTeacher } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      school_active_id: '',
      timeAbled: false,
      Info: {},
      teamList: [],
      courseList: [],
      teacherList: [],
      rules: {
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: "请输入活动天数",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.school_active_id = data.id;
      this.getInfo();
    }
    this.getTeacherList();
    this.getCourseList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      getSchoolActiveInfo({
        id: this.school_active_id
      }).then(res => {
        this.Info = res.data;
        let nowtimeSe = new Date().getTime(); //获取当前时间戳
        let activetimeSe = new Date(this.Info.start_time).getTime();
        if (nowtimeSe < activetimeSe) {
          this.timeAbled = true;
        } //可编辑
      });
    },

    getTeacherList() {
      getTeacherList().then(res => {
        this.teacherList = res.data;
      });
    },
    getCourseList() {
      courseList().then(res => {
        this.courseList = res.data;
      });
    },
    addCourse(index) {
      if (!this.Info.teamCourse[index].courses) {
        this.Info.teamCourse[index].courses = [];
      }
      this.Info.teamCourse[index].courses.push({
        course_id: null,
        teacher_id: null,
        address: null
      });
    },
    deleteCourse(index1, index2) {
      this.Info.teamCourse[index1].courses.splice(index2, 1);
    },
    setCourseTeamTeacher() {
      //审核通过后 在活动开始之前可修改课程指导教师
      let nowtimeSe = new Date().getTime(); //获取当前时间戳
      let activetimeSe = new Date(this.Info.start_time).getTime();
      console.log(nowtimeSe + '>' + activetimeSe);
      if (nowtimeSe > activetimeSe) {
        this.$root.ElMessage.error({
          message: '活动已开始 不能修改信息'
        });
        return false;
      }
      let data = [];
      for (let i in this.Info.teamCourse) {
        let course = this.Info.teamCourse[i].courses;
        for (let j in course) {
          data.push({
            id: course[j].id,
            teacher_id: course[j].teacher_id
          });
        }
      }
      setCourseTeamTeacher({
        school_active_id: this.Info.id,
        teamCourse: data
      }).then(res => {
        this.$root.msgResult(res, '/base/activeApply/activeSchoolList');
      });
    },
    subData(state) {
      //提交
      if (!this.Info.check_msg && state == 3) {
        this.$root.ElMessage.error('请填写失败原因');
        return false;
      }
      let m = '';
      if (state == 2) {
        let c = this.Info.teamCourse;
        for (let i in c) {
          if (c[i].courses && c[i].courses.length > 0) {
            let d = c[i].courses;
            let CourseId = [];
            for (let j in d) {
              if (!d[j].course_id) {
                m = '添加的课程安排中，课程不能为空';
                this.$root.ElMessage.error(m);
                return false;
              } else {
                if (CourseId.includes(d[j].course_id)) {
                  m = '一个班同一门课程只能选择一次，请核对数据';
                  this.$root.ElMessage.error(m);
                  return false;
                } else {
                  CourseId.push(d[j].course_id);
                }
              }
            }
          }
        }
      }
      if (m) {
        this.$root.ElMessage.error(m);
        return false;
      }
      let data = {
        id: this.Info.id,
        check_msg: this.Info.check_msg,
        state: state,
        teamCourse: this.Info.teamCourse
      };
      checkApplyActive(data).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};