import {createRouter,createWebHashHistory} from 'vue-router' //createWebHashHistory
import Login from '@/view/login.vue'
import AutoLogin from '@/view/autoLogin.vue'
import Home from '@/view/home.vue'
import Index1 from '@/view/index1.vue'
import Index2 from '@/view/index2.vue'
import Index3 from '@/view/index3.vue'
import Register from '@/view/base/register.vue'
import CheckBase from '@/view/base/checkBase.vue'
import SchoolEdit from '@/view/admin/adminSchool/schoolEdit.vue'
import BaseEdit from '@/view/admin/adminBase/baseEdit.vue'
import CourseEdit from '@/view/base/baseCourse/courseEdit.vue'
import ActiveBaseEdit from '@/view/base/baseActive/activeBaseEdit.vue'
import ActiveBaseInfo from '@/view/school/schoolActive/activeBaseInfo.vue'
import ActiveApplyInfo from '@/view/base/activeApply/activeApplyInfo.vue'
import MyActiveInfo from '@/view/school/schoolActive/myActiveInfo.vue'
import BaseTeacherEdit from '@/view/base/baseUser/teacherEdit.vue'
import EvaBaseRate from '@/view/school/schoolEva/evaBaseRate.vue'
import TeamEdit from '@/view/school/schoolManage/teamEdit.vue'
import BaseInfo from "@/view/school/schoolActive/baseInfo";
import TeacherEdit from '@/view/base/baseUser/teacherEdit.vue'
import StudentEdit from '@/view/school/schoolUser/studentEdit.vue'
import SchoolEnjoyEdit from '@/view/school/schoolManage/schoolEnjoyEdit.vue'
import noticeEdit from '@/view/admin/notice/noticeEdit.vue'
import orderList from '@/view/base/order/orderList.vue'
import CheckOrderInfo from '@/view/mobile/checkOrderInfo.vue'
import GradeAndTeamDataList from '@/view/school/schoolActiveData/gradeAndTeamDataList.vue'
import SchoolDataInfo from '@/view/school/schoolActiveData/schoolDataInfo.vue'
import GradeDataInfo from '@/view/school/schoolActiveData/gradeDataInfo.vue'
import TeamDataInfo from '@/view/school/schoolActiveData/teamDataInfo.vue'
import StudentDataInfo from '@/view/school/schoolActiveData/studentDataInfo.vue'
import BaseDataInfo from '@/view/base/baseActiveData/baseDataInfo.vue'
/* 用户移动端静态 */
import MobileLogin from '@/view/mobile/login.vue'
import MobileIndex from '@/view/mobile/index.vue'
import MobileScanQrcode from '@/view/mobile/scanQrcode.vue'
import ActivePayList from '@/view/school/schoolActive/activePayList'
export default createRouter({
    history:createWebHashHistory(),
    //动态注册路由
    routes: [
        {
            path: '/',
            name: '',
            component: Login
        },
        {
            path: '/autoLogin',
            name: 'AutoLogin',
            component: AutoLogin
        },
        {
            path: '/ADMIN',
            name: 'ADMIN',
            component: Login
        },
        {
            path: '/BASE',
            name: 'BASE',
            component: Login
        },
        {
            path: '/SCHOOL',
            name: 'SCHOOL',
            component: Login
        },
        {
            path:'/base/register',
            name: 'Register',
            component: Register
        },
        {
            path:'/base/checkBase',
            name: 'CheckBase',
            component: CheckBase
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            redirect:'/index',
            children:[
                {
                    path: '/index1',
                    name: 'Index1',
                    component: Index1
                },
                {
                    path: '/index2',
                    name: 'Index2',
                    component: Index2
                },
                {
                    path: '/index3',
                    name: 'Index3',
                    component: Index3
                },
                {
                    path: '/admin/adminSchool/schoolEdit',
                    name: 'SchoolEdit',
                    component: SchoolEdit
                },
                {
                    path: '/admin/adminBase/BaseEdit',
                    name: 'BaseEdit',
                    component: BaseEdit
                },
                {
                    path: '/base/baseCourse/courseEdit',
                    name: 'CourseEdit',
                    component: CourseEdit
                },
                {
                    path: '/base/baseActive/activeBaseEdit',
                    name: 'ActiveBaseEdit',
                    component: ActiveBaseEdit
                },
                {
                    path: '/school/schoolActive/activeBaseInfo',
                    name: 'ActiveBaseInfo',
                    component: ActiveBaseInfo
                },
                {
                    path: '/base/activeApply/activeApplyInfo',
                    name: 'ActiveApplyInfo',
                    component: ActiveApplyInfo
                },
                {
                    path: '/base/baseUser/teacherEdit',
                    name: 'BaseTeacherEdit',
                    component: BaseTeacherEdit
                },
                {
                    path:'/school/schoolEva/evaBaseRate',
                    name:'EvaBaseRate',
                    component:EvaBaseRate
                },
                {
                    path:'/school/schoolManage/teamEdit',
                    name:'TeamEdit',
                    component:TeamEdit
                },
                {
                    path:'/school/schoolUser/teacherEdit',
                    name:'TeacherEdit',
                    component:TeacherEdit
                },
                {
                    path:'/school/schoolUser/studentEdit',
                    name:'StudentEdit',
                    component:StudentEdit
                },
                {
                    path:'/school/schoolManage/schoolEnjoyEdit',
                    name:'SchoolEnjoyEdit',
                    component:SchoolEnjoyEdit
                },
                {
                    path:'/admin/notice/noticeEdit',
                    name:'noticeEdit',
                    component:noticeEdit
                },
                {
                    path:'/base/order/orderList',
                    name:'orderList',
                    component:orderList
                },
                {
                    path:'/school/schoolActive/activePayList',
                    name:'ActivePayList',
                    component:ActivePayList
                },
                {
                    path:'/school/schoolActive/myActiveInfo',
                    name:'MyActiveInfo',
                    component:MyActiveInfo
                },
                {
                    path:'/school/schoolActive/baseInfo',
                    name:'BaseInfo',
                    component:BaseInfo
                },
                {
                    path:'/school/schoolActiveData/gradeAndTeamDataList',
                    name:'GradeAndTeamDataList',
                    component:GradeAndTeamDataList
                },
                {
                    path:'/school/schoolActiveData/teamDataInfo',
                    name:'TeamDataInfo',
                    component:TeamDataInfo
                },
                {
                    path:'/school/schoolActiveData/gradeDataInfo',
                    name:'GradeDataInfo',
                    component:GradeDataInfo
                },
                {
                    path:'/school/schoolActiveData/studentDataInfo',
                    name:'StudentDataInfo',
                    component:StudentDataInfo
                },
                {
                    path:'/school/schoolActiveData/schoolDataInfo',
                    name:'SchoolDataInfo',
                    component:SchoolDataInfo
                },
                {
                    path:'/base/baseActiveData/baseDataInfo',
                    name:'BaseDataInfo',
                    component:BaseDataInfo
                }
            ]
        },
        {
            path: '/mobile/login',
            name: 'MobileLogin',
            component: MobileLogin
        },
        {
            path: '/mobile/index',
            name: 'MobileIndex',
            component: MobileIndex
        },
        {
            path: '/mobile/scanQrcode',
            name: 'MobileSacnQrcode',
            component: MobileScanQrcode
        },
        {
            path: '/mobile/checkOrderInfo',
            name: 'CheckOrderInfo',
            component: CheckOrderInfo
        }
    ],
/*    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            redirect:'/rent/visitorList',
            children:[
                {
                    path: '/rent/visitorList',
                    name: 'VisitorList',
                    component: VisitorList
                },
                {
                    path: '/rent/checkList',
                    name: 'CheckList',
                    component: CheckList
                },
                {
                    path: '/rent/authorizationList',
                    name: 'AuthorizationList',
                    component: AuthorizationList
                },
                {
                    path: '/charging/accountList',
                    name: 'AccountList',
                    component: AccountList
                },
                {
                    path: '/notice/informationList',
                    name: 'InformationList',
                    component: InformationList
                },
                {
                    path: '/notice/introduceList',
                    name: 'IntroduceList',
                    component: IntroduceList
                },
                {
                    path: '/notice/informationTypeList',
                    name: 'InformationTypeList',
                    component: InformationTypeList
                },
                {
                    path: '/rent/checkServerEdit',
                    name: 'CheckServerEdit',
                    component: CheckServerEdit
                }
            ]
        },
    ]*/
})
