import http from '@/utils/request.js'
export function login(data){
    return http.post('/currency/user_login/login',data)
}
export function autoLogin(data){
    return http.post('/currency/user_login/auto_login',data)
}
export function loginOut(){
    localStorage.clear();
}
/* 修改密码 */
export function changePass(data){
    return http.post('/currency/common_user/change_password',data);
}
//获取当前登录用户详情
export function getInfo(){
    return http.get('/currency/common_method/info')
}
//获取商户详情
export function getBazeInfo(data){
    return http.get('/baze/baze_user/base_info',data)
}
export function isMobile() {//判断当前设备是否是移动端
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
export function is_weixn() {//判断当前设备是否是微信端
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
/* 首页 数据统计 */
export function indexData(type){
    return http.get('/currency/index_data/index_data'+type);
}
/* 获取待办事项 */
export function getCheckBazeNum(){
    return http.get('/admin/admin_baze/get_check_baze_num');
}
/* 查询分类列表 */
export function GoodsTypeList(data){
    return http.get('/baze/active_goods/list',data)
}
/* end */
/* 学校  */
export function schoolPage(data){
    return http.get('/admin/admin_school/school_page',data);
}
export function editSchool(data){
    return http.post('/admin/admin_school/school_edit',data);
}
export function getSchoolNoticePage(data){//获取风采
    return http.get('/school/school_manage/notice_page',data)
}
export function editSchoolNotice(data){//获取风采
    return http.post('/school/school_manage/notice_edit',data)
}
export function getSchoolNoticeInfo(data){//获取风采
    return http.get('/school/school_manage/notice_info',data)
}
export function UpdateAllSchool(){
    return http.post('/admin/admin_school/async_school');
}
export function UpdateAll(){
    return http.post('/admin/admin_school/update_all_data');
}
/* 年级 班级 */
export function getGradeAll(){//获取所有年级
    return http.get('/currency/common_method/grade_list')
}
export function getTeamList(){//年级结构获取当前学校的 班级
    return http.get('/school/school_team/team_list')
}
export function getTeamPage(data){//年级结构获取当前学校的 班级
    return http.get('/school/school_team/page',data)
}
export function teamEdit(data){//班级编辑
    return http.post('/school/school_team/edit',data)
}
export function UpdateAllTeam(data){
    return http.post('/school/school_team/async_school_team',data);
}
/* end */
/* 获取教师 */
export function TeacherPage(data){
    return http.get('/currency/common_user/teacher_page',data);
}
export function getTeacherList(data){
    return http.get('/currency/common_user/teacher_list',data);
}
export function teacherEdit(data){
    return http.post('/currency/common_user/teacher_edit',data);
}
export function UpdateAllTeacher(data){
    return http.post('/school/school_user/async_school_teacher',data);
}
/* end */
/* 学生 */
export function StudentPage(data){
    return http.get('/school/school_user/page',data);
}
export function StudentEdit(data){
    return http.post('/school/school_user/edit',data);
}
export function UpdateAllStudent(data){
    return http.post('/school/school_user/async_school_student',data);
}
/* end */
/* 商户  */
export function basePage(data){
    return http.get('/admin/admin_baze/baze_page',data);
}
export function registerBase(data){
    return http.post('/currency/user_login/register',data)
}
export function editBase(data){
    return http.post('/admin/admin_baze/baze_edit',data)
}
export function checkBase(data){
    return http.post('/admin/admin_baze/check_baze',data)
}
export function base_wx_info(){//编辑商户详情信息
    return http.post('/baze/baze_user/base_wx_info')
}
export function base_wx_edit(data){//编辑商户详情信息
    return http.post('/baze/baze_user/base_wx_edit',data)
}
export function base_order_page(data){//商户订单
    return http.get('/baze/baze_order/page',data);
}
export function get_order_info(data){//校验后获取订单详情
    return http.get('/baze/baze_order/get_order_info',data);
}
export function base_order_check(data){//核销校验
    return http.post('/baze/baze_order/check_order',data);
}
export function base_order_check_ok(data){//确认核销
    return http.post('/baze/baze_order/check_order_ok',data);
}
/* end */
/* 商户活动数据 */
export function getBaseActiveData(data){
    return http.get('/baze/baze_data/baze_active_data',data);
}
/* 商户 审核学校活动通过后 在活动开始前可以修改课程指导教师 */
export function setCourseTeamTeacher(data){
    return http.post('/baze/baze_active/apply_active_set_teacher',data);
}
/* 课程 */
export function editCourse(data,type){
    return http.post('/baze/baze_course/edit',data)
}
export function coursePage(data){
    return http.get('/baze/baze_course/page',data);
}
export function courseList(data){
    return http.get('/baze/baze_course/list',data);
}
export function courseListById(data){
    return http.get('/baze/baze_course/list_arr',data);
}
/* end */
/* 活动产品 */
export function editBaseActive(data){
    return http.post('/baze/baze_active/edit',data)
}
export function baseActivePage(data){
    return http.get('/baze/baze_active/page',data);
}
export function baseActiveOfSchoolPage(data) {
    return http.get('/school/school_active/school_baze_active_page', data);
}
export function active_apply_page(data){//获取活动申请列表
    return http.get('/baze/baze_active/active_apply_page', data);
}
export function getSchoolActiveInfo(data){//获取活动申请详情
    return http.get('/baze/baze_active/get_school_active_info',data);
}
export function checkApplyActive(data){//审核
    return http.post('/baze/baze_active/check_apply_active',data)
}
export function insureList(){
    return http.get('/currency/common_method/insure_list');
}
/* end */

/* 学校活动 */
export function applyActive(data){
    return http.post('/school/school_active/apply_active',data);
}
export function school_my_active_page(data){
    return http.get('/school/school_active/school_my_active_page',data);
}
export function sendSchoolActive(data){
    return http.post('/school/school_active/send_active',data);
}
export function school_active_pay_page(data){
    return http.get('/school/school_active/school_active_pay_page',data);
}
/* end */
export function getIndexData(){
    return http.get('/index/data')
}
export function getMenu(){
    return http.get('/admin/admin_menu/menu_all')
}
export function getMyMenu(){
    return http.get('/admin/admin_menu/role_menu')
}
export function addStudent(data,type='post'){
    return http[type]('/student',data)
}
export function addAsset(data,type='post'){
    return http[type]('/utensil',data)
}

/* 学校活动数据 */
export function getGradeTeamActiveObject(data){//获取单次活动参与对象 年级班级 分层
    return http.get('/school/school_data/get_grade_team_active_object',data)
}
export function getSchoolActiveData(data){//年级活动数据
    return http.get('/school/school_data/school_active_data',data)
}
export function getGradeActiveData(data){//年级活动数据
    return http.get('/school/school_data/grade_active_data',data)
}
export function getTeamActiveData(data){//班级活动数据
    return http.get('/school/school_data/team_active_data',data)
}
export function getStudentActiveData(data){//学生个人活动数据
    return http.get('/school/school_data/student_active_data',data)
}

/* 评价 */
export function schoolEvaBase(data){
    return http.post('/school/eva_school/save_eva',data)
}
export function getSchoolEvaBase(data){
    return http.get('/school/eva_school/get_eva',data)
}
export function baseEvaPage(data){
    return http.get('/baze/baze_eva/eva_page',data)
}

/* 管理员端 全局配置 */
export function systemConfig(){
    return http.get('/admin/admin_config/info')
}
export function editSystemConfig(data){
    return http.post('/admin/admin_config/edit',data)
}

/* 公用方法 */
export function uploadFile(data){//上传图片
    return http.file('/currency/common_method/upload_file',data)
}
export function downFile(name){//下载模板
    return http.down('/'+name+'/download?Authorization=Bearer '+localStorage.getItem('token'));
}
export function importFile(data,url){
    return http.file(url,data)
}
export function exportFile(url){//导出
    return http.down(url);
}
export function sendNotice(data){//模板消息推送
    return http.post('/wx/wx_method/send_wx_notice',data)
}
export function sendBazeNotice(data){
    return http.post('/baze/baze_active/send_baze_notice',data)
}
/* 验证微信签名 */
export function getWxSign(data){
    return http.get('/wx/wx_method/get_wx_sign',data);
}
/* 发送短信 */
export function sendSms(data){
    return http.post('/currency/common_method/send_sms',data)
}