import "core-js/modules/es.array.push.js";
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'; //全局导入ele 消息
import { useRouter, useRoute } from 'vue-router'; //路由跳转
import { getMyMenu, isMobile, getGradeAll, GoodsTypeList, sendNotice } from "@/api/api";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { projectName, USERTYPE, STATELIST, BASETYPE, COURSELIST, ACTIVETYPE, CommentList, ACTIVELIST, PAY_STATE, STUDY_LEVEL } from "@/utils/config";
import city from "@/utils/city.json";
import addImg from "@/static/addImg.png";
import defaultImg from "@/static/img/active/default-img.png";
import defaultVideo from "@/static/img/active/default-video.png";
import request from "./utils/request";
export default {
  components: {
    ElConfigProvider //展示中文
  },

  name: 'App',
  data() {
    //定义全局变量 其他组件使用 this.$root获取
    return {
      ElMessage: ElMessage,
      ElMessageBox: ElMessageBox,
      ElLoading: ElLoading,
      useRouter: useRouter(),
      useRoute: useRoute(),
      menuList: [],
      stateList: STATELIST,
      commentList: CommentList,
      locale: zhCn,
      cityList: city,
      gradeList: [],
      goodsTypeList: {
        1: [],
        2: [],
        all: []
      },
      defaultImg: defaultImg,
      defaultVideo: defaultVideo,
      addImg: addImg,
      projectName: projectName,
      userType: USERTYPE,
      baseType: BASETYPE,
      courseType: COURSELIST,
      activeType: ACTIVETYPE,
      activeList: ACTIVELIST,
      payType: PAY_STATE,
      studyLevel: STUDY_LEVEL
    };
  },
  mounted() {
    let eqment = this._isMobile();
    if (!eqment && localStorage.getItem('token')) {
      this.getMenuList();
      this.getGradeList();
      this.getGoodsTypeList();
    }
    if (eqment) {//移动端
    }
  },
  methods: {
    //定义全局方法 其他组件使用 this.$root获取
    _isMobile() {
      //判断当前设备是否是移动端
      return isMobile();
    },
    loadingOpen() {
      let loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading;
    },
    setRouter() {
      //动态添加路由
      for (let i in this.menuList) {
        let firstM = this.menuList[i];
        if (firstM.url) {
          let rout = {
            path: firstM.url,
            component: () => import('@/view' + firstM.url),
            name: firstM.name,
            meta: {
              title: firstM.title,
              noCache: true
            },
            children: []
          };
          this.useRouter.addRoute('Home', rout);
        } else if (firstM.child.length > 0) {
          for (let j in firstM.child) {
            let secondM = firstM.child[j];
            if (secondM.url) {
              let rout = {
                path: secondM.url,
                component: () => import('@/view' + secondM.url),
                name: secondM.name,
                meta: {
                  title: secondM.title,
                  noCache: true
                },
                children: []
              };
              this.useRouter.addRoute('Home', rout);
            }
          }
        }
      }
    },
    getMenuList() {
      getMyMenu().then(res => {
        this.menuList = res.data;
        let url = this.getFirstMenuUrl();
        this.setRouter(); //根据菜单注册组件
        setTimeout(() => {
          if (localStorage.getItem('menuUrl')) {
            this.$root.useRouter.push(localStorage.getItem('menuUrl'));
          } else {
            this.$root.useRouter.push(url);
          }
        }, 500);
      });
    },
    getFirstMenuUrl() {
      let url = '';
      for (let i in this.menuList) {
        if (this.menuList[i].path) {
          url = this.menuList[i].path;
          return url;
        } else {
          if (this.menuList[i].child.length > 0) {
            for (let j in this.menuList[i].child) {
              if (this.menuList[i].child[j].url) {
                return url;
              }
            }
          }
        }
        return url;
      }
    },
    getGradeList() {
      getGradeAll().then(res => {
        this.gradeList = res.data;
      });
    },
    getByName(name, name1, objectName, value) {
      //根据属性1获取属性2的值
      let v = '';
      this[objectName].find(res => {
        if (res[name] == value) {
          v = res[name1];
          return false;
        }
      });
      return v;
    },
    getByAreaCode(code1, code2, code3) {
      //根据省市区code获取 名称
      let text = '';
      let city = this.cityList;
      for (let p in city) {
        if (city[p].value == code1) {
          text = city[p].label;
          if (code2) {
            let city2 = city[p].children;
            for (let c in city2) {
              if (city2[c].value == code2) {
                text = text + ' ' + city2[c].label;
                if (code3) {
                  let city3 = city2[c].children;
                  for (let a in city3) {
                    if (city3[a].value == code3) {
                      text = text + ' ' + city3[a].label;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return text;
    },
    resultMsg(res) {
      //请求返回提示
      if (res.success) {
        this.ElMessage({
          type: 'success',
          message: res.msg
        });
        this.useRouter.go(-1);
      } else {
        this.ElMessage.error(res.msg);
      }
    },
    msgResult(res, url) {
      if (res.success) {
        this.ElMessage({
          type: 'success',
          message: res.msg
        });
        if (url) {
          this.useRouter.replace(url);
        }
      } else {
        this.ElMessage.error(res.msg);
      }
    },
    loginOut() {
      let re = isMobile();
      let name = this.getByName('type', 'userType', 'userType', localStorage.getItem('userType'));
      localStorage.clear();
      if (re) {
        location.replace("/#/mobile/login");
      } else {
        location.replace("/#/" + name);
      }
    },
    getGoodsTypeList() {
      //获取商品分类 并转化格式
      GoodsTypeList().then(res => {
        let data = [];
        let dataType1 = [];
        let dataType2 = [];
        let dataOne = [];
        for (let i in res.data) {
          let data1 = {
            value: res.data[i].code,
            label: res.data[i].name,
            children: []
          };
          dataOne.push({
            value: res.data[i].code,
            label: res.data[i].name,
            children: []
          }); //全部一级 可用于搜索
          for (let j in res.data[i].children) {
            data1.children.push({
              value: res.data[i].children[j].code,
              label: res.data[i].children[j].name,
              children: []
            });
          }
          if (res.data[i].type == 1) {
            dataType1.push(data1);
          }
          if (res.data[i].type == 2) {
            dataType2.push(data1);
          }
          data.push(data1);
        }
        this.goodsTypeList = {
          1: dataType1,
          2: dataType2,
          all: data,
          oneAll: dataOne
        };
      });
    },
    imgFileApi(arr) {
      for (let i in arr) {
        arr[i].url = this.projectName + '/' + arr[i].url;
      }
      return arr;
    },
    sendWxNotice() {
      sendNotice().then(res => {
        console.log(res);
      });
    },
    CopyUrl(data) {
      console.log(data);
      var oInput = document.createElement("input"); // 创建input标签存放地址
      oInput.value = data; // 给input标签赋值
      document.body.appendChild(oInput); // 将input标签添加到body里
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none"; // 隐藏input
      this.ElMessage({
        type: 'success',
        message: '复制成功'
      });
    },
    imageSrc(url) {
      //V3 动态引入图片
      let s = url;
      import(url).then(res => {
        s = res.default;
      });
      return s;
    }
  }
};