import "core-js/modules/es.array.push.js";
import { uploadFile, editBase, checkBase, getBazeInfo, systemConfig } from "@/api/api";
import { projectName } from "@/utils/config";
import defaultImg from "@/static/addImg.png";
export default {
  data() {
    return {
      cityValue: [],
      userType: localStorage.getItem('userType'),
      Info: [],
      type: '',
      baseImgArr: [{
        v: 'img_yy',
        n: '营业执照'
      }, {
        v: 'img_jy',
        n: '经营许可证'
      }, {
        v: 'img_sw',
        n: '税务登记证'
      }, {
        v: 'img_frz',
        n: '法人身份证正面'
      }, {
        v: 'img_frf',
        n: '法人身份证反面'
      }, {
        v: 'img_xf',
        n: '消防许可证'
      }, {
        v: 'img_bx',
        n: '商户保险单'
      }, {
        v: 'img_cy',
        n: '餐饮服务许可证、卫生许可证'
      }, {
        v: 'img_pp',
        n: '平面图'
      }, {
        v: 'img_qm',
        n: '商户全貌'
      }, {
        v: 'img_dm',
        n: '大门'
      }, {
        v: 'img_dl',
        n: '主要道路'
      }, {
        v: 'img_bgs',
        n: '商户办公室'
      }, {
        v: 'img_jxl',
        n: '教学楼通道'
      }, {
        v: 'img_tcc',
        n: '停车场'
      }, {
        v: 'img_js',
        n: '教室或多功能室、卫生许可证'
      }],
      projectName: projectName,
      default_img: defaultImg,
      rules: {
        name: [{
          required: true,
          message: "请输入商户名称",
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: "请选择商户类型",
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: "请输入联系电话",
          trigger: 'blur'
        }],
        year: [{
          required: true,
          message: '请输入建成年份',
          trigger: 'blur'
        }],
        fanwei: [{
          required: true,
          message: '请输入经营范围',
          trigger: 'blur'
        }],
        ziben: [{
          required: true,
          message: '请输入注册资本',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: "统一信用代码",
          trigger: 'blur'
        }],
        yingyeqixian: [{
          required: true,
          message: "营业期限",
          trigger: 'blur'
        }],
        zhusuo: [{
          required: true,
          message: "住所",
          trigger: 'blur'
        }],
        legal_person: [{
          required: true,
          message: "法人姓名",
          trigger: 'blur'
        }],
        id_no: [{
          required: true,
          message: "请输入法人身份证",
          trigger: 'blur'
        }],
        bank_name: [{
          required: true,
          message: "请输入开户银行名称",
          trigger: 'blur'
        }],
        bank_address: [{
          required: true,
          message: "请输入开户银行地址",
          trigger: 'blur'
        }],
        bank_no: [{
          required: true,
          message: "请输入开户银行账号",
          trigger: 'blur'
        }],
        img_changsuo_arr: [{
          required: true,
          message: "请上传经营场所实况图片",
          trigger: 'blur'
        }],
        max_num: [{
          required: true,
          pattern: /^[0-9]*$/,
          message: '请输入合法的人数',
          trigger: 'blur'
        }],
        level: [{
          required: true,
          message: '请输入纳税信用等级',
          trigger: 'blur'
        }],
        img_yy: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }],
        img_frz: [{
          required: true,
          message: '请上传法人身份证正面',
          trigger: 'blur'
        }],
        img_frf: [{
          required: true,
          message: '请上传法人身份证反面',
          trigger: 'blur'
        }],
        dormitory_size: [{
          required: true,
          message: '单日住宿量',
          trigger: 'blur'
        }],
        hold_size: [{
          required: true,
          message: '单次用餐量',
          trigger: 'blur'
        }],
        base_area: [{
          required: true,
          message: '室内场所面积',
          trigger: 'blur'
        }],
        province: [{
          required: true,
          message: '请选择地区',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.systemConfigInfo();
    if (data.id) {
      this.getInfo(data.id);
    } else {
      let Info = JSON.parse(localStorage.getItem('user'));
      this.getInfo(Info.id);
      this.reflexData();
    }
    if (data.type == 'showEdit') {
      //商户修改资质重新提交
      this.type = data.type;
    }
  },
  methods: {
    getInfo(id) {
      getBazeInfo({
        id: id
      }).then(res => {
        res.data.type = parseInt(res.data.type);
        this.Info = res.data;
        this.cityValue = [parseInt(this.Info.province), parseInt(this.Info.city), parseInt(this.Info.area)]; //回显省市区县
        this.reflexData();
      });
    },
    systemConfigInfo() {
      systemConfig().then(res => {
        if (res.data) {
          this.systemInfo = res.data;
        }
      });
    },
    reflexData() {
      if (this.Info.img_insure_arr) {
        this.Info.img_insure_arr = JSON.parse(this.Info.img_insure_arr);
      } else {
        this.Info.img_insure_arr = [];
      }
      if (this.Info.img_hangye_arr) {
        this.Info.img_hangye_arr = JSON.parse(this.Info.img_hangye_arr);
      } else {
        this.Info.img_hangye_arr = [];
      }
      if (this.Info.img_changsuo_arr) {
        this.Info.img_changsuo_arr = JSON.parse(this.Info.img_changsuo_arr);
      } else {
        this.Info.img_changsuo_arr = [];
      }
      if (this.Info.anquan_file_arr) {
        this.Info.anquan_file_arr = JSON.parse(this.Info.anquan_file_arr);
      } else {
        this.Info.anquan_file_arr = [];
      }
      if (this.Info.yingji_file_arr) {
        this.Info.yingji_file_arr = JSON.parse(this.Info.yingji_file_arr);
      } else {
        this.Info.yingji_file_arr = [];
      }
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (name == 'img_insure_arr' || name == 'img_hangye_arr' || name == 'img_changsuo_arr' || name == 'anquan_file_arr' || name == 'yingji_file_arr') {
            this.Info[name].push({
              name: fileName,
              url: this.projectName + '/' + res.data
            });
          } else {
            this.Info[name] = res.data;
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    qxBank() {
      this.$root.useRouter.go(-1);
    },
    onSubmit() {
      if (this.type == 'showEdit') {
        this.$root.ElMessageBox.confirm('重新提交审核后，你的账号将处于待审核状态，届时将无法正常使用，待审核通过后才能恢复正常使用，你确定要继续提交审核吗?', '重要提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '暂不提交',
          type: 'warning',
          draggable: true
        }).then(() => {
          this.submitContent();
        });
      } else {
        this.submitContent();
      }
    },
    submitContent() {
      this.$refs['Info'].validate(valid => {
        if (valid) {
          editBase(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                message: res.msg,
                type: 'success'
              });
              this.$root.loginOut();
            } else {
              this.$root.ElMessage.error({
                message: res.msg
              });
            }
          });
        }
      });
    },
    checkSubmit(state) {
      if (state == 3) {
        if (!this.Info.check_msg) {
          this.$root.ElMessage.error({
            message: '请填写审核备注'
          });
          return false;
        }
      }
      let data = {
        id: this.Info.id,
        state: state,
        check_msg: this.Info.check_msg
      };
      checkBase(data).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    }
  }
};