import { editSchool } from "@/api/api";
import { stateList } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      cityValue: [],
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      Info: {
        id: '',
        name: '',
        code: ''
      },
      stateList: stateList,
      adminList: [],
      levelList: [],
      type: 0,
      checkMuneSer: [],
      rules: {
        code: [{
          required: true,
          message: "请输入学校编号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入学校名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          editSchool(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.message
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.message);
            }
          });
        }
      });
    }
  }
};