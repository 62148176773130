import "core-js/modules/es.array.push.js";
import { editBaseActive, uploadFile, insureList, courseList } from "@/api/api";
import { projectName } from "@/utils/config";
import editor from "@/components/editor.vue";
import editor1 from "@/components/editor1.vue";
export default {
  name: "index",
  components: {
    editor,
    editor1
  },
  data() {
    return {
      cityValue: [],
      goodsCodeValue: [],
      projectName: projectName,
      dialogVisible: false,
      dialogImageUrl: '',
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      Info: {
        id: '',
        name: '',
        active_goods_type_code1: '',
        type: '',
        cost: '',
        province: '',
        process: [],
        img_json: [],
        cost_detail_text: []
      },
      adminList: [],
      levelList: [],
      goodsTypeList: [],
      type: 0,
      checkMuneSer: [],
      insureList: [],
      courseList: [],
      rules: {
        name: [{
          required: true,
          message: "请输入名称",
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: "请选择服务对象",
          trigger: 'blur'
        }],
        active_goods_type_code1: [{
          required: true,
          message: "请选择活动类型",
          trigger: 'blur'
        }],
        cost: [{
          required: true,
          message: "请输入产品销售价",
          trigger: 'blur'
        }],
        period: [{
          required: true,
          message: "请选择学段",
          trigger: 'blur'
        }],
        province: [{
          required: true,
          message: "请选择地区",
          trigger: 'blur'
        }]
      }
    };
  },
  created() {
    this.getInsureList();
    this.getCourseList();
    this.$root.getGoodsTypeList();
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      this.cityValue = [parseInt(this.Info.province), parseInt(this.Info.city), parseInt(this.Info.area)]; //回显省市区县
      this.goodsCodeValue[0] = this.Info.active_goods_type_code1;
      if (this.Info.active_goods_type_code2) {
        this.goodsCodeValue[1] = this.Info.active_goods_type_code2;
      }
      if (this.Info.img_json) {
        this.Info.img_json = JSON.parse(this.Info.img_json);
      } else {
        this.Info.img_json = [];
      }
      if (this.Info.course_json) {
        this.Info.course_json = JSON.parse(this.Info.course_json);
      } else {
        this.Info.course_json = [];
      }
      ;
      if (this.Info.process) {
        this.Info.process = JSON.parse(this.Info.process);
      } else {
        this.Info.process = [];
      }
      ;
      if (this.Info.cost_detail_text) {
        this.Info.cost_detail_text = JSON.parse(this.Info.cost_detail_text);
      } else {
        this.Info.cost_detail_text = [];
      }
      ;
      this.setText();
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInsureList() {
      insureList().then(res => {
        this.insureList = res.data;
      });
    },
    goBuyInsure() {
      if (!this.Info.insure_id) {
        this.$root.ElMessage.error('请选择保险类型');
        return false;
      }
      let url = '';
      this.insureList.find(res => {
        if (res.id == this.Info.insure_id) {
          window.open(res.url);
        }
      });
    },
    getCourseList() {
      courseList().then(res => {
        this.courseList = res.data;
      });
    },
    areaChange(e) {
      //选择地区
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    subData() {
      //提交
      if (this.goodsCodeValue.length > 0) {
        this.Info.active_goods_type_code1 = this.goodsCodeValue[0];
        this.Info.active_goods_type_code2 = this.goodsCodeValue[1];
      }
      console.log(this.Info);
      this.$refs['Info'].validate(valid => {
        if (valid) {
          let method = 'post';
          if (this.Info.id) {
            method = 'put';
          }
          if (this.Info.img_json && this.Info.img_json.length > 0) {
            this.Info.img_url = this.Info.img_json[0].url.split('api/')[1];
          }
          editBaseActive(this.Info, method).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (name == 'img_json' || name == 'process') {
            this.Info[name].push({
              name: fileName,
              url: this.projectName + '/' + res.data
            });
          } else {
            this.Info[name] = res.data;
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    showImage(e) {
      this.dialogImageUrl = e.url;
      this.dialogVisible = true;
    },
    addProcess() {
      this.Info.process.push({
        time: '',
        text: '',
        address: ''
      });
    },
    delProcess(index) {
      console.log(index);
      this.Info.process.splice(index, 1);
    },
    addCost() {
      this.Info.cost_detail_text.push({
        name: '',
        money: '',
        text: ''
      });
    },
    delCost(index) {
      this.Info.cost_detail_text.splice(index, 1);
    },
    setText() {
      this.$refs.editor1.setText(this.Info.active_book_text);
      this.$refs.editor.setText(this.Info.content);
    },
    getText(html) {
      this.Info.content = html;
    },
    getText1(html) {
      this.Info.active_book_text = html;
    },
    refreshData() {
      this.$forceUpdate();
    }
  }
};