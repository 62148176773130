import "core-js/modules/es.array.push.js";
import lineChart from '@/components/lineChart.vue';
import options from '@/utils/chartOption';
import { getStudentActiveData } from "@/api/api";
export default {
  name: "index",
  components: {
    lineChart
  },
  data() {
    return {
      Info: {
        school_active_id: '',
        team_id: '',
        name: ''
      },
      chartData: [],
      alarmtresChartData: [],
      chartId: 'student',
      sumScore: 0
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.school_active_id && data.student_id) {
      this.Info.school_active_id = data.school_active_id;
      this.Info.student_id = data.student_id;
      this.Info.name = data.name;
      this.getData();
    }
  },
  methods: {
    getData() {
      getStudentActiveData(this.Info).then(res => {
        this.chartData = res.data;
        if (this.chartData.evaAnswer) {
          this.imageSrc(this.chartData.evaAnswer);
        }
        if (this.chartData.dataEva) {
          let chartPieData = options.pie;
          let seData = [];
          let sumScore = 0;
          for (let i in this.chartData.dataEva) {
            seData.push({
              name: this.chartData.dataEva[i].eva_type,
              y: this.chartData.dataEva[i].num
            });
            sumScore = sumScore + this.chartData.dataEva[i].num;
          }
          this.sumScore = sumScore;
          chartPieData.series[0].data = seData;
          this.alarmtresChartData = chartPieData;
          this.$refs.lineChart.showCharts('student', this.alarmtresChartData); //调用子组件方法
        }
      });
    },

    imageSrc(data) {
      //V3 动态引入图片
      for (let i in data) {
        import('@/static/img/xunzhang/' + data[i].option_id + '.png').then(res => {
          this.chartData.evaAnswer[i]['img'] = res.default;
        });
      }
    }
  }
};