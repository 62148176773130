import "core-js/modules/es.array.push.js";
import lineChart from '@/components/lineChart.vue';
import options from '@/utils/chartOption';
import { getBaseActiveData } from "@/api/api";
export default {
  name: "index",
  components: {
    lineChart
  },
  data() {
    return {
      Info: {
        school_active_id: ''
      },
      chartData: [],
      alarmtresChartData: [],
      chartId: 'school',
      sumCom: 0
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.school_active_id) {
      this.Info.school_active_id = data.school_active_id;
      this.getData();
    }
  },
  methods: {
    getData() {
      getBaseActiveData(this.Info).then(res => {
        this.chartData = res.data;
        if (this.chartData.evaAnswer) {
          this.imageSrc(this.chartData.evaAnswer);
        }
        if (this.chartData.dataEva) {
          let chartPieData = options.column;
          let seData = [];
          let sumCom = 0;
          for (let i in this.chartData.dataEva) {
            let s = parseFloat(this.chartData.dataEva[i]);
            seData.push({
              name: i,
              y: s,
              drilldown: i
            });
            sumCom = sumCom + s;
          }
          this.sumCom = sumCom;
          chartPieData.series[0].data = seData;
          this.alarmtresChartData = chartPieData;
          this.$refs.lineChart.showCharts('school', this.alarmtresChartData); //调用子组件方法
          this.$forceUpdate();
        }
      });
    },
    showGradeData(grade_code) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/gradeDataInfo",
        query: {
          school_active_id: this.Info.school_active_id,
          grade_code: grade_code
        }
      });
    },
    imageSrc(data) {
      //V3 动态引入图片
      for (let i in data) {
        import('@/static/img/xunzhang/' + data[i].option_id + '.png').then(res => {
          this.chartData.evaAnswer[i]['img'] = res.default;
        });
      }
    }
  }
};