import { editCourse, getGradeAll } from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  components: {
    editor
  },
  name: "index",
  data() {
    return {
      grades: [],
      Info: {
        id: '',
        name: '',
        code: '',
        type: ''
      },
      showInfo: false,
      rules: {
        name: [{
          required: true,
          message: "请输入课程名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.getGradeList();
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      this.setText();
      if (this.Info.grade) {
        this.grades = this.Info.grade.split(',');
        this.grades = this.grades.map(function (data) {
          return +data;
        });
      }
    }
    if (data.showInfo == 1) {
      this.showInfo = true;
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    getGradeList() {
      getGradeAll().then(res => {
        this.$nextTick(() => {
          this.gradeList = res.data;
        });
        //this.$forceUpdate();
        //this.$nextTick
      });
    },

    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.grades.length > 0) {
            this.Info.grade = this.grades.join(',');
            //this.$root.ElMessage.error('请选择适用年级');
            //return false;
          }

          editCourse(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    setText() {
      this.$refs.editor.setText(this.Info.text);
    },
    getText(html) {
      this.Info.text = html;
    }
  }
};