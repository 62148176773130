import "core-js/modules/es.array.push.js";
import { getWxSign, base_order_check } from "@/api/api";
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      index: 1,
      List: [],
      wx: wx,
      text: '【*-*】17【*-*】26【*-*】40274【*-*】2023090714541062881034【*-*】',
      pageInfo: {
        pageSize: 100,
        current: 1,
        state: [2, 3]
      }
    };
  },
  created() {
    this.getWxSign();
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
    //this.checkOrder(this.text);//测试
  },
  methods: {
    checkOrder(text) {
      //检验核销码
      base_order_check({
        text: text
      }).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.replace('/mobile/checkOrderInfo?order_no=' + res.data);
        } else {
          this.$root.ElMessage.error(res.msg);
          this.$root.useRouter.go(-1);
        }
      });
    },
    is_weixn() {
      return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
    },
    scanCode() {
      if (!this.is_weixn()) {
        alert('请用微信打开页面，扫码功能仅支持微信页面');
        return;
      }
      sessionStorage.setItem('isInSerach', '1');
      let hrefStr = location.href;
      let href = hrefStr;
      if (hrefStr.indexOf('?') !== -1) {
        href = hrefStr.split('?')[0];
      }
      location.href = `http://sao315.com/w/api/saoyisao?redirect_uri=${encodeURIComponent(href)}`; //使用第三方页面调起扫一扫 仅仅支持微信
    },

    getWxSign() {
      let that = this;
      let url = location.href.split('#')[0];
      getWxSign({
        url: encodeURIComponent(url)
      }).then(res => {
        this.wx.config({
          debug: false,
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId,
          // 必填，公众号的唯一标识
          timestamp: res.timestamp,
          // 必填，生成签名的时间戳
          nonceStr: res.nonceStr,
          // 必填，生成签名的随机串
          signature: res.signature,
          // 必填，签名
          jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
        });
      });

      this.wx.ready(() => {
        // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
        // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
        this.wx.checkJsApi({
          // 判断当前客户端版本是否支持指定JS接口
          jsApiList: ['scanQRCode'],
          success: res => {
            // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult.scanQRCode === true) {
              this.wx.scanQRCode({
                // 微信扫一扫接口
                desc: 'scanQRCode desc',
                needResult: 1,
                // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ['qrCode', 'barCode'],
                // 可以指定扫二维码还是一维码，默认二者都有
                success: res => {
                  if (res && res.resultStr) {
                    that.checkOrder(JSON.stringify(res.resultStr));
                  } else {
                    that.ElMessage.error('取消扫码');
                    that.$root.useRouter.push({
                      path: "/mobile/index"
                    });
                  }
                }
              });
            } else {
              this.$root.ElMessage.error({
                message: '抱歉，当前客户端版本不支持扫一扫'
              });
            }
          },
          fail: res => {
            // 检测getNetworkType该功能失败时处理
            this.$root.ElMessage.error({
              message: 'fail' + res
            });
          }
        });
      });
      /* 处理失败验证 */
      this.wx.error(res => {
        // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
        this.$root.ElMessage.error({
          message: '配置验证失败: ' + res.errMsg
        });
      });
    }
  }
};