import "core-js/modules/es.array.push.js";
import { login, loginOut, isMobile, is_weixn, getWxSign, sendSms } from "@/api/api";
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      isRemeber: false,
      wx: wx,
      loginInfo: {
        username: '',
        password: '',
        type: 1
      },
      autoLogin: false,
      clientHeight: '',
      clientWidth: '',
      ticket: ''
    };
  },
  created() {
    loginOut();
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.loginInfo.type = this.$root.getByName('userType', 'type', 'userType', this.$root.useRoute.name) || 1; //this.$root.useRoute.name 获取当前的路由名称
    this.clientHeight = `${document.documentElement.clientHeight}`;
    this.clientWidth = `${document.documentElement.clientWidth}`;
  },
  methods: {
    onSubmit() {
      if (!this.loginInfo.username) {
        this.$root.ElMessage.error({
          message: '请输入用户名'
        });
        return false;
      }
      if (!this.loginInfo.password) {
        this.$root.ElMessage.error({
          message: '请输入密码'
        });
        return false;
      }
      login(this.loginInfo).then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.data2);
          window.localStorage.setItem('userType', this.loginInfo.type);
          window.localStorage.setItem('user', JSON.stringify(res.data));
          if (this.loginInfo.type == 3) {
            if (res.data.state != 2) {
              //未审核通过的商户
              this.$root.ElMessage({
                message: '当前状态不能正常使用，请联系管理员审核',
                type: 'warning'
              });
              this.$root.useRouter.push({
                path: "/base/checkBase",
                query: {
                  id: res.data.id
                }
              });
              return false;
            }
          }
          this.$root.getMenuList();
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          //this.$root.useRouter.push("/home");
          this.$root.useRouter.push("/index" + this.loginInfo.type); //跳转至对应角色的首页
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    }
  }
};