import { registerBase, systemConfig } from "@/api/api";
export default {
  data() {
    return {
      systemInfo: [],
      agree_name: '',
      dialogVisible: false,
      agree: false,
      agree_privacy_agreement: false,
      repassword: '',
      loginInfo: {
        name: '',
        username: '',
        password: '',
        type: 3
      },
      clientHeight: '',
      clientWidth: '',
      marginTOP: 300
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let that = this;
    that.systemConfigInfo();
    this.clientHeight = `${document.documentElement.clientHeight}`;
    this.clientWidth = `${document.documentElement.clientWidth}`;
    let s = (this.clientHeight - 500) / 2;
    if (s < 0) {
      s = 0;
    }
    this.marginTOP = s;
    window.onresize = function () {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      this.clientWidth = `${document.documentElement.clientWidth}`;
      let s = (this.clientHeight - 500) / 2;
      if (s < 0) {
        s = 0;
      }
      this.marginTOP = s;
      that.$forceUpdate();
    };
  },
  methods: {
    systemConfigInfo() {
      systemConfig().then(res => {
        if (res.data) {
          this.systemInfo = res.data;
        }
      });
    },
    showXY(name) {
      this.dialogVisible = true;
      this.agree_name = name;
    },
    onSubmit() {
      if (!this.loginInfo.username) {
        this.$root.ElMessage.error({
          message: '请输入用户名'
        });
        return false;
      }
      if (!this.loginInfo.name) {
        this.$root.ElMessage.error({
          message: '请输入商户名称'
        });
        return false;
      }
      if (!this.loginInfo.password) {
        this.$root.ElMessage.error({
          message: '请输入密码'
        });
        return false;
      }
      if (this.loginInfo.password != this.repassword) {
        this.$root.ElMessage.error({
          message: '两次密码不一致'
        });
        return false;
      }
      if (!this.agree) {
        this.$root.ElMessage.error({
          message: '请先勾选服务协议'
        });
        return false;
      }
      //this.$root.useRouter.push("/home");//路由跳转
      registerBase(this.loginInfo).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          this.goLogin();
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    },
    goLogin() {
      this.$root.useRouter.replace("/BASE");
    }
  }
};